
  import { defineComponent, ref, inject } from "vue";
  import { useRoute } from "vue-router";
  import axios from "@/api/axios";
  import Toast from "@/components/UI/Toast";
  import dateFormat from "@/utils/dateFormat";
  import router from "@/router";
  import qs from "qs";
  import Modal from "@/components/UI/Modal";
  export default defineComponent({
    name: "virtuallyInfo",
    setup() {
      const userInfo = inject("userInfo") as any;
      const biaowuPriceOpen = ref()
      const entity = ref(null);
      const list = ref([]);
      const route = useRoute();
      const id = route.query.id;
      axios
        .get("/M/User/virtuallyInfoShow/" + id, { params: { validate: true } })
        .then((res) => {
          if (res.data.success) {
            entity.value = res.data.obj.orderVO;
            list.value = res.data.obj.list;
            biaowuPriceOpen.value = res.data.obj.biaowuPriceOpen;
          } else {
            if(res.data.msg = 'NotLogin'){
              Toast({
                type: "warning",
                title: "请先登录！",
              });
              router.push({
                name:'login'
              })
              return false;
            }else{
              Toast({
                type: "error",
                title: res.data.msg,
              });
            }
          }
        })
        .catch((e) => {
          Toast({
            type: "error",
            title: e.message || e,
          });
        });
      function toClickAddInfo() {
        Modal({
          title: "合并信息",
          content: "确定合并产品信息到购物车中吗？",
          confirmText: "确定",
          closeText: "取消",
          onConfirm: () => {
            const data = qs.stringify({
              id:id,
            });
            axios.post("/M/User/clickAddInfo", data,{})
              .then((res) => {
                if (res.data.success) {
                  Toast({
                    type: "success",
                    title: res.data.msg,
                  });
                  //跳转购物车页面
                  router.push({
                    name:'ShopCar'
                  });
                  return false;
                } else {
                  Toast({
                    type: "error",
                    title: res.data.msg,
                  });
                }
              })
              .catch((err) => {
                Toast({
                  type: "error",
                  title: err.message || err
                });
              });
          }
        });
      }
      return {
        entity,
        dateFormat,
        list,
        biaowuPriceOpen,
        userInfo,
        toClickAddInfo
      };
    },
  });
